.modal {
  position: fixed; /* фиксированное положение */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.5); /* цвет фона */
  z-index: 1050;
  opacity: 0; /* по умолчанию модальное окно прозрачно */
  -webkit-transition: opacity 200ms ease-in; 
  -moz-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in; /* анимация перехода */
  pointer-events: none; /* элемент невидим для событий мыши */
  margin: 0;
  padding: 0;
  }
  
  .modal.enter-done {
    opacity: 1;
    pointer-events: visible;
  }
  
  .modal.exit {
    opacity: 0;
  }
  
  .modal-content {
    width: 500px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    transform: translateY(-200px);
  }

  .modal-content-contact {
    width: 1050px;
    background-color: #fff; 
    transition: all 0.3s ease-in-out;
    transform: translateY(-50px);
  }
  
  .modal.enter-done .modal-content {
    transform: translateY(0);
  }
  
  .modal.exit .modal-content .modal-content-contact {
    transform: translateY(-200px);
  }
  
  .modal-header,
  .modal-footer {
    padding: 10px;
  }
  
  .modal-title {
    margin: 0;
  }
  
  .modal-body {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  .column-word { 
    /* word-break: break-all; */
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
   }
  