
/*======================
    404 page
=======================*/


.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
  .link_404{			 
    margin-top: 10px;
    padding: 10px 40px;
    border-radius: 10px;
    background-color: #e0570a;
    color: #fff;
    outline: none;
    text-decoration: none;
  }
  .contant_box_404{ 
    margin-top:-50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;}