.input-file-c {
	position: relative;
	display: inline-block;
}
.input-file-c-btn {
	position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	font-size: 14px;
	vertical-align: middle;
	color: rgb(255 255 255);
	text-align: center;
	border-radius: 10px;
	background-color: #e0570a;
	line-height: 22px;
	height: 40px;
	padding: 10px 20px;
	box-sizing: border-box;
	border: none;
	margin: 0;
	transition: background-color 0.2s;
}
.input-file-c-text {
	padding: 0 10px;
	line-height: 40px;
	display: inline-block;
}
.input-file-c input[type=file] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
}
 
/* Focus */
.input-file-c input[type=file]:focus + .input-file-c-btn {
	box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
 
/* Hover/active */
.input-file-c:hover .input-file-c-btn {
	background-color:  #663b22;
}
.input-file-c:active .input-file-c-btn {
	background-color:  #663b22;
}
 
/* Disabled */
.input-file-c input[type=file]:disabled + .input-file-c-btn {
	background-color: #eee;
}