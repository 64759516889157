.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #f5f4f4;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

a {
  border: 1px none #000;
  color: #7e1812;
  text-decoration: underline;
}

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1280px;
  padding-top: 25px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.phone-link {
  margin-left: 40px;
  font-family: Varela, sans-serif;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

.brand {
  color: #333;
}

.link {
  color: #333;
  text-decoration: none;
}

.body {
  background-color: #f5f4f4;
  background-image: url('../images/Group-47.png');
  background-position: 50% 0%;
  background-size: auto;
  background-repeat: no-repeat;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.navbar {
  background-color: transparent;
}

.button {
  display: block;
  margin-left: 60px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: #c24239;
  border-radius: 20px;
  background-color: transparent;
  font-family: Varela, sans-serif;
  color: #c24239;
  font-size: 16px;
}

.nav-menu {
  margin-left: 40px;
  color: #311201;
  font-size: 16px;
}

.image {
  margin-bottom: 25px;
}

.container-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1280px;
  /* margin-top: 40px; */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-2 {
  position: relative;
  z-index: 1;
  width: 620px;
  height: 450px;
  max-width: none;
  margin-right: 0px;
  margin-bottom: 8px;
  margin-left: -106px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.image-3 {
  position: relative;
  z-index: 1;
  width: 522px;
  margin-right: -55px;
  padding-right: 0px;
}

.heading-1 {
  position: static;
  width: 100%;
  margin: 0px 0px -200px;
  font-family: Oswald, sans-serif;
  font-size: 64px;
  line-height: 90px;
  font-weight: 500;
  text-align: center;
  /* text-transform: uppercase; */
}

.div-block {
  position: relative;
  z-index: 3;
  width: 900px;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.container-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1280px;
  margin-top: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.div-block-3 {
  display: block;
  width: 28%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 15px;
  background-color: #fff;
}

.heading-4 {
  padding-top: 16px;
  font-family: Varela, sans-serif;
  color: #333;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
}

.link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  text-decoration: none;
}

.link-block:hover {
  background-color: transparent;
  box-shadow: 0 8px 20px 12px rgba(185, 183, 183, 0.26);
}

.hero-section {
  padding-bottom: 0px;
}

.container-4 {
  max-width: 1280px;
  margin-top: 0px;
  padding-top: 60px;
  padding-right: 16px;
  padding-left: 16px;
}

.heading-2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Oswald, sans-serif;
  color: #333;
  font-weight: 400;
}

.div-block-4 {
  width: 25%;
  padding-right: 20px;
  border-right: 1px solid #d6d6d6;
}

.news-date {
  margin-top: 20px;
  font-size: 12px;
}

.news-text {
  margin-top: 10px;
  font-family: Merriweather, serif;
  font-size: 20px;
  line-height: 28px;
}

.link-2 {
  display: inline-block;
  margin-top: 20px;
}

.div-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.news-block-2 {
  width: 25%;
  padding-right: 20px;
  padding-left: 25px;
  border-right: 1px solid #d6d6d6;
}

.news-block-last {
  width: 25%;
  padding-right: 20px;
  padding-left: 25px;
  border-right: 1px none #d6d6d6;
}

.grid {
  margin-top: 40px;
  margin-bottom: 40px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.div-block-6 {
  border: 1px none #000;
  border-radius: 15px;
  background-color: #f2edea;
}

.div-block-6:hover {
  background-color: #ffefe6;
}

.object-link {
  margin-top: 10px;
  color: #333;
  text-align: center;
  text-decoration: none;
}

.link-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 24px;
  padding-bottom: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.image-4 {
  margin-top: 0px;
  margin-bottom: 7px;
}

.image-4-copy {
  margin-top: 30px;
  margin-bottom: 7px;
}

.section {
  background-color: #e0570a;
}

.container-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1280px;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.image-5 {
  background-color: transparent;
}

.heading-5 {
  font-family: Merriweather, serif;
  line-height: 48px;
  font-weight: 400;
}

.div-block-7 {
  width: 50%;
  padding-top: 30px;
  padding-left: 60px;
}

.button-1 {
  margin-top: 20px;
  padding: 12px 40px;
  border-radius: 20px;
  background-color: #ffefe6;
  color: #c24239;
  font-size: 18px;
}

.container-6 {
  max-width: 1280px;
  margin-bottom: 0px;
  padding: 40px 16px 60px;
}

.link-block-3 {
  text-decoration: none;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  padding-right: 16px;
  padding-left: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
}

.text-block {
  margin-top: 10px;
  text-align: center;
}

.text-block-2 {
  margin-top: 10px;
  text-align: center;
}

.text-block-3 {
  margin-top: 10px;
  text-align: center;
}

.text-block-4 {
  margin-top: 10px;
  text-align: center;
}

.text-block-5 {
  margin-top: 10px;
  text-align: center;
}

.link-3 {
  margin-top: 16px;
}

.container-7 {
  max-width: 1280px;
  padding: 40px 16px 60px;
}

.section-2 {
  background-color: #ffefe6;
}

.div-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.heading-6 {
  color: #7e1812;
  font-weight: 400;
}

.div-block-11 {
  padding-top: 20px;
}

.div-block-12 {
  padding-top: 20px;
}

.text-field {
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #c24239;
  background-color: transparent;
  color: #333;
}

.text-field::-webkit-input-placeholder {
  color: #333;
  font-size: 16px;
}

.text-field:-ms-input-placeholder {
  color: #333;
  font-size: 16px;
}

.text-field::-ms-input-placeholder {
  color: #333;
  font-size: 16px;
}

.text-field::placeholder {
  color: #333;
  font-size: 16px;
}

.form {
  border-bottom: 1px none #000;
}

.div-block-13 {
  width: 45%;
}

.div-block-14 {
  width: 100%;
}

.text-field-2 {
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #c24239;
  background-color: transparent;
}

.text-field-2::-webkit-input-placeholder {
  color: #333;
}

.text-field-2:-ms-input-placeholder {
  color: #333;
}

.text-field-2::-ms-input-placeholder {
  color: #333;
}

.text-field-2::placeholder {
  color: #333;
}

.textarea {
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #c24239;
  background-color: transparent;
}

.textarea::-webkit-input-placeholder {
  color: #333;
}

.textarea:-ms-input-placeholder {
  color: #333;
}

.textarea::-ms-input-placeholder {
  color: #333;
}

.textarea::placeholder {
  color: #333;
}

.submit-button {
  margin-top: 10px;
  padding: 10px 40px;
  border-radius: 10px;
  background-color: #e0570a;
}

/* Disabled */
.submit-button:disabled {
	background-color: #eee;
}

.container-8 {
  max-width: 1280px;
}

.section-3 {
  background-color: #ffc09d;
}

.div-block-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 60px 16px 40px;
}

.div-block-15 {
  width: 30%;
}

.div-block-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.div-block-17 {
  width: 30%;
}

.text-block-6 {
  margin-top: 20px;
  font-size: 14px;
}

.link-4 {
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: none;
}

.text-block-7 {
  font-size: 20px;
  text-align: right;
}

.text-block-8 {
  margin-top: 20px;
  padding-top: 0px;
  text-align: right;
}

.text-block-9 {
  margin-top: 65px;
  color: #696969;
  font-size: 14px;
  text-align: right;
}

.div-block-18 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.container-9 {
  max-width: 1280px;
  padding-right: 16px;
  padding-bottom: 60px;
  padding-left: 16px;
}

.heading-man {
  margin-top: 48px;
  font-family: Oswald, sans-serif;
  font-size: 40px;
  font-weight: 500;
  text-align: center;
}

.grid-2 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-rows: auto auto auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto auto auto;
}

.div-block-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.wrappernewslist {
  width: 250px;
  height: 200px;

}
.newslist {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../static/images/2456034.jpg');
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: cover;
} 
.newslist img {
  min-width: 100%;
  min-height: 100%;
  flex-shrink: 0;
}
.newslist1 {
  background-image: url('../../static/images/2456034.jpg');
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: cover;
}  
.heading-7 {
  margin-top: 0px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.text-block-10 {
  margin-bottom: 10px;
  line-height: 26px;
}

.div-block-20 {
  padding-left: 20px;
}

.div-block-21 {
  margin-top: 48px;
}

.div-block-22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 48px;
}

.div-block-23 {
  width: 30%;
}

.div-block-24 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.link-sidebar {
  margin-left: 10px;
  font-family: Merriweather, serif;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

.link-sidebar:hover {
  color: #7e1812;
}

.div-block-25 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-object {
  margin-top: 48px;
  font-family: Oswald, sans-serif;
  font-size: 36px;
  font-weight: 500;
  text-align: left;
}

.text-block-11 {
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
}

.link-5 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.nav-link {
  color: #333;
}

.nav-link.w--current {
  color: #7e1812;
  font-weight: 600;
}

.nav-link-2 {
  color: #333;
}

.nav-link-2.w--current {
  font-family: 'Open Sans', sans-serif;
  color: #7e1812;
  font-weight: 600;
}

.body-2 {
  font-family: 'Open Sans', sans-serif;
}

@media screen and (max-width: 991px) {
  .phone-link {
    font-size: 14px;
  }

  .image {
    margin-left: 20px;
  }

  .image-2 {
    width: 400px;
    height: 300px;
    margin-left: -59px;
  }

  .image-3 {
    width: 380px;
    margin-right: -34px;
  }

  .heading-1 {
    font-size: 56px;
    line-height: 72px;
  }

  .heading-4 {
    padding-top: 8px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
  }

  .link-block {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .news-text {
    font-size: 16px;
    line-height: 24px;
  }

  .object-link {
    font-size: 14px;
  }

  .link-block-2 {
    padding-left: 4px;
  }

  .image-4 {
    max-width: 70%;
  }

  .image-4-copy {
    max-width: 70%;
  }

  .div-block-8 {
    display: block;
  }

  .text-block {
    font-size: 14px;
  }

  .text-block-2 {
    font-size: 14px;
  }

  .text-block-4 {
    font-size: 14px;
  }

  .text-block-5 {
    font-size: 14px;
  }

  .text-block-8 {
    font-size: 14px;
  }

  .text-block-9 {
    font-size: 12px;
  }

  .image-6 {
    max-width: 70%;
  }

  .image-7 {
    max-width: 70%;
  }

  .image-8 {
    max-width: 70%;
  }

  .image-9 {
    max-width: 70%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .phone-link {
    width: 100%;
    margin-left: 0px;
    font-size: 10px;
  }

  .button {
    width: 100%;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    font-size: 14px;
    text-align: center;
  }

  .image {
    width: 400px;
    margin-left: 0px;
  }

  .image-2 {
    width: 300px;
    height: auto;
  }

  .image-3 {
    width: 280px;
  }

  .heading-1 {
    font-size: 48px;
    line-height: 64px;
  }

  .heading-4 {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 18px;
  }

  .div-block-4 {
    width: 100%;
  }

  .link-2 {
    margin-top: 10px;
  }

  .div-block-5 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .news-block-2 {
    width: 100%;
    padding-left: 0px;
  }

  .news-block-last {
    width: 100%;
    padding-left: 0px;
  }

  .grid {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .object-link {
    font-size: 13px;
    line-height: 16px;
  }

  .heading-5 {
    font-size: 24px;
    line-height: 32px;
  }

  .button-1 {
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
  }

  .text-block-6 {
    font-size: 13px;
  }

  .link-4 {
    font-size: 15px;
  }

  .text-block-7 {
    font-size: 18px;
  }
}

@media screen and (max-width: 479px) {
  .container {
    padding-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .phone-link {
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    text-align: center;
  }

  .button {
    width: 55%;
    height: 35px;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .image {
    width: 150px;
    margin-bottom: 0px;
  }

  .container-2 {
    margin-top: 10px;
  }

  .image-2 {
    width: 210px;
    margin-left: -34px;
  }

  .image-3 {
    width: 170px;
    margin-right: 0px;
    margin-bottom: 6px;
  }

  .heading-1 {
    margin-bottom: -25px;
    font-size: 28px;
    line-height: 36px;
  }

  .container-3 {
    margin-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-3 {
    width: 90%;
    margin-bottom: 10px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .news-text {
    font-size: 14px;
  }

  .grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
  }

  .link-block-2 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .container-5 {
    padding-top: 0px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-7 {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
  }

  .button-1 {
    margin-top: 0px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .div-block-8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-top: 16px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .div-block-9 {
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .text-block {
    text-align: left;
  }

  .text-block-2 {
    text-align: left;
  }

  .text-block-4 {
    text-align: left;
  }

  .text-block-5 {
    text-align: left;
  }

  .container-7 {
    padding-bottom: 40px;
  }

  .div-block-10 {
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-13 {
    width: 100%;
    margin-top: 40px;
  }

  .div-block-14 {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-15 {
    width: 100%;
  }

  .div-block-16 {
    width: 100%;
    padding-top: 16px;
    padding-left: 0px;
  }

  .div-block-17 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 24px;
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }

  .text-block-7 {
    font-weight: 600;
  }

  .text-block-9 {
    margin-top: 10px;
  }

  .div-block-18 {
    display: none;
  }

  .image-10 {
    max-width: 80%;
  }

  .image-11 {
    max-width: 80%;
  }

  .image-12 {
    max-width: 80%;
  }

  .text-block-12 {
    font-size: 14px;
  }

  .text-block-13 {
    font-size: 14px;
  }

  .text-block-14 {
    font-size: 14px;
  }

  .image-13 {
    width: 120px;
  }
}

#w-node-_4af29f3a-8e46-fbf0-25b7-1a0a3c52ed9d-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-ee1c9b50-e881-e19d-f4ed-fa6eb771121e-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_830c7a0f-fff8-ac2a-8a66-115dd4b81b16-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_0b6f7e19-2192-5577-4e57-e1a016f3f6b8-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-f21e59cd-9c45-52af-dac9-790e696daf06-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-f7ba7140-c080-4621-20e6-f8b73ed415b4-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_634a03a4-a66f-ea4f-4622-48c680316ef2-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_27e29fab-5054-2a6f-fe82-18daa1149433-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_6801acfa-8f0c-e898-c3fe-93f3c3435d06-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_30ec5d59-57ef-d347-c637-7d4811c26040-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_193df9b8-4a01-e435-41b5-24a716dab87f-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_512eaf1c-81fe-2947-7f9e-b3072b085093-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_16fe9354-aadc-faf2-2135-ecb216252ce7-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_5d3f4af0-4821-c6c2-f478-3975923cbc39-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_4a2b6a8a-476c-0cd3-0eaf-41a79c7e248e-c104c2b1 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

.chunk-panel {
  width: 505px;
  height: 165px;
  overflow-y: auto;
  padding: 18px;
  margin-top: 40px;
  background-color: rgba(191, 191, 191, 0.15);
}

.segment-size,
.loaded-size {
  margin-left: 3px;
}

.note {
  display: block;
  font-size: 10pt;
  color: #484848;
  margin-left: 9px;
}

.note > span {
  font-weight: 700;
}

.dx-toast-content {
  min-width: 300px;
  max-width: 400px;
}

.dx-toast-error {
  background-color: #d9534f;
}