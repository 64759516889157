.dx-overlay-content {
    background-color: lightgray;
}
#toolbar {
    background-color: rgba(191, 191, 191, .15);
    padding: 5px 10px;
}
.dx-toolbar-button .dx-button {
    background-color: rgba(191, 191, 191, -0.15);
    border: none;
}
.dx-toolbar-button > .dx-toolbar-item-content {
    margin-left: -7px;
}
.dx-list-item-icon {
    margin-right: 10px;
}
#view {
    margin-left: 10px;
    margin-top: 10px;
}
